//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { PreferenceService } from "../../services/preference.service";
import { Preference } from "../../pojo/PeferenceI";

@TypeManagerDecorator("s25-breakpoint")
@Component({
    selector: "s25-breakpoint",
    template: `
        <div id="index{{ this.indexCount }}">
            <label for="newIndex{{ this.indexCount }}" class="c-margin-bottom--single">
                <div class="c-margin-bottom-quarter">Add a Breakpoint:</div>
                <input
                    type="text"
                    style="text-transform:uppercase"
                    class="s25-input"
                    id="newIndex{{ this.indexCount }}"
                    [(ngModel)]="this.newIndex"
                    name="newIndex"
                    (ngModelChange)="this.onChangeValue()"
                />
                <button
                    class="aw-button aw-button--primary c-margin-left--quarter"
                    (click)="add(this.newIndex)"
                    [disabled]="!this.newIndex || !isValidIndex(this.newIndex)"
                >
                    Add
                </button>
            </label>
            <div *ngIf="this.newIndex && !this.isValidIndex(this.newIndex)" class="ngRed">
                Invalid input, please enter {{ this.indexType !== "number" ? "number or letter" : "a number" }}.
            </div>
            <p class="c-margin-bottom--quarter">Breakpoints:</p>
            <div *ngIf="this.index.length > 0">
                <div *ngFor="let i of this.index">
                    <span class="c-margin-right--half ngInlineBlock ngBreakpoint">{{ i }} </span>
                    <svg (click)="remove(i)" class="c-svgIcon ngCpointer" role="img">
                        <title>Delete</title>
                        <use xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#close-x"></use>
                    </svg>
                </div>
            </div>

            <div
                *ngIf="this.message && this.message !== ''"
                class="ngGreen ngBold cn-alert cn-alert--success c-margin-bottom--single"
                role="alert"
            >
                <div class="cn-alert__icon cn-icon" name="alert--info">
                    <svg class="cn-svg-icon" role="img">
                        <title>Success Alert</title>
                        <use
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"
                        ></use>
                    </svg>
                </div>
                <div class="cn-alert__label">
                    <span>{{ this.message }}</span>
                </div>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BreakpointComponent implements OnInit {
    @Input() indexType: string = "";
    @Input() prefName: string = "";
    @Input() prefType: Preference.PrefType = "S";
    index: any = [];
    newIndex: any;
    loading: boolean = false;
    initLoading: boolean = true;
    indexCount: number = 0;

    static count: number = 0;

    message: string = "";

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.indexCount = S25BreakpointComponent.count++;
    }

    onChangeValue() {
        this.message = "";
        this.cd.detectChanges();
    }

    ngOnInit() {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
        this.getPrefs();
        this.cd.detectChanges();
    }

    isValidIndex(n: any) {
        if (n && this.indexType !== "number") {
            let letters = /^[0-9a-zA-Z]+$/;
            return n.match(letters) || (parseInt(n) === parseFloat(n) && n > 0);
        } else {
            return parseInt(n) === parseFloat(n) && n > 0;
        }
    }

    getPrefs() {
        PreferenceService.getPreferences([this.prefName], this.prefType).then((resp) => {
            if (resp[this.prefName] && resp[this.prefName].value) {
                this.index = resp[this.prefName].value
                    .split(",")
                    .map((item: any) => {
                        return parseInt(item) || item;
                    })
                    .sort(S25Util.sortAlphaNum());
            }
            this.initLoading = false;
            this.cd.detectChanges();
        });
    }

    add(item: any) {
        let unique = true;
        this.indexType === "number"
            ? (unique = this.index.indexOf(parseInt(item)) === -1)
            : (unique = this.index.indexOf(parseInt(item)) === -1 && this.index.includes(item.toUpperCase()) === false);
        if (unique) {
            this.index.push(parseInt(this.newIndex) || this.newIndex.toUpperCase());
            this.index = this.index.sort(S25Util.sortAlphaNum());
            let newValue = this.index.map((i: any) => i).join(",");
            PreferenceService.setPreference(this.prefName, newValue, this.prefType);
            this.message = "Success!";
            this.newIndex = "";
            this.cd.detectChanges();
        } else {
            this.newIndex = "";
            this.cd.detectChanges();
        }
    }

    remove(item: any) {
        this.index = this.index.filter((c: any) => {
            return c && c !== item;
        });
        let newValue = this.index.map((i: any) => i).join(",");
        PreferenceService.setPreference(this.prefName, newValue, this.prefType);
        this.message = "Success!";
        this.cd.detectChanges();
    }
}
