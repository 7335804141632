import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { Table } from "../s25-table/Table";
import { BuildingService } from "../../services/building.service";
import { Bind } from "../../decorators/bind.decorator";
import { S25TableComponent } from "../s25-table/s25.table.component";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { SearchCriteriaService } from "../../services/search/search-criteria/search.criteria.service";
import { S25EditableTextComponent } from "../s25-editable/s25-editable-text/s25.editable.text.component";
import { jSith } from "../../util/jquery-replacement";
import { SpaceService } from "../../services/space.service";
import { S25ItemI } from "../../pojo/S25ItemI";
import { CustomAttributeService } from "../../services/custom.attribute.service";
import { CacheRepository } from "../../decorators/cache.decorator";
import { DropDownItem } from "../../pojo/DropDownItem";
import { AlertService } from "../../services/alert.service";

@TypeManagerDecorator("s25-ng-generate-buildings")
@Component({
    selector: "s25-ng-generate-buildings",
    template: `
        <div *ngIf="isInit">
            <h2>Bulk Create Buildings</h2>
            <p class="ngFinePrint">
                Use this tool to create buildings based on your current data. If several buildings are already
                configured try bulk editing on a location search.
            </p>
            <br />

            <label for="source-prop">Create buildings in bulk based on this property of your locations.</label>
            <br />
            <select
                id="source-prop"
                #sourceProp
                class="cn-form__control"
                name="sourceProperty"
                (change)="chooseProperty(sourceProp.value)"
            >
                <option value="">Choose a source property</option>
                <option value="categories">Categories</option>
                <option value="attributes">Custom Attributes</option>
                <option value="partitions">Partitions</option>
            </select>
            <br />
            <div class="dropdown">
                <s25-generic-dropdown
                    *ngIf="sourceProperty === 'attributes' && this.custAtrbStringList"
                    [items]="this.custAtrbStringList"
                    [placeholder]="'Select from Custom Attributes'"
                    [searchEnabled]="true"
                    [(chosen)]="custAtrb"
                    (chosenChange)="chooseCustomAttribute($event)"
                ></s25-generic-dropdown>
            </div>

            <!--
            <s25-ng-dropdown-search-criteria
                *ngIf="sourceProperty === 'attributes'"
                [type]="'locationCustomAttributes'"
                [(chosen)]="custAtrb"
                (chosenChange)="chooseCustomAttribute($event)"
                [popoverClass]="'qtip-content'"
            ></s25-ng-dropdown-search-criteria>  -->

            <label for="include-locations-toggle" class="ngBold c-margin-top--double "
                >Do you want to add locations to the new buildings?</label
            >
            <s25-toggle-button
                id="include-locations-toggle"
                [(modelValue)]="assignLocations"
                [falseLabel]="'No'"
                [trueLabel]="'Yes'"
                [toggleLabel]="'includeLocations'"
                [description]="'Include Locations'"
                (modelValueChange)="toggleChanged()"
            ></s25-toggle-button>

            <button
                class="aw-button aw-button--primary c-margin-bottom--half c-margin-top--single"
                (click)="onSave()"
                [disabled]="this.selectedItems.length === 0"
            >
                {{ assignLocations ? "Create Buildings and add locations" : "Create Buildings" }}
            </button>

            <div
                *ngIf="msg && msg !== ''"
                class="ngGreen ngBold cn-alert cn-alert--success c-margin-bottom--single"
                role="alert"
            >
                <div class="cn-alert__icon cn-icon" name="alert--info">
                    <svg class="cn-svg-icon" role="img">
                        <title>Success Alert</title>
                        <use
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#check"
                        ></use>
                    </svg>
                </div>
                <div class="cn-alert__label">
                    <span>{{ msg }}</span>
                </div>
            </div>

            <div *ngIf="matchFound && matchFound.length > 0" class="c-margin-bottom--half c-margin-top--half">
                <div class="ngRed">The following buildings could not be created, building name already exists.</div>
                <ul>
                    <li *ngFor="let m of matchFound" class="small">{{ m.itemName }}</li>
                </ul>
            </div>

            <s25-ng-table
                *ngIf="sourceProperty && tableOn"
                [dataSource]="tableData"
                [hasRefresh]="true"
                [hasFilter]="true"
                [columnSortable]="true"
                [hasSelect]="true"
                [hasSelectAll]="true"
                [pivotThreshold]="800"
                (selectedChange)="selectedChange($event)"
            ></s25-ng-table>
        </div>
    `,
    styles: `
        .top {
            max-width: 1200px;
        }

        .header {
            display: flex;
            justify-content: space-between;
        }

        .header > button {
            margin-bottom: 1em;
        }

        .dropdown {
            max-width: 720px;
        }
    `,
})
export class S25GenerateBuildingsComponent implements OnInit {
    isInit = false;
    sourceItems: S25ItemI;
    tableData: Table.DataSource;
    buildings: any;
    selectedItems: any = [];
    msg: string;
    matchFound: any = [];
    typeName: string = "partition";

    sourceProperty: string; // "categories" | "attributes" | "partitions";
    custAtrb: DropDownItem;
    assignLocations: boolean = true;
    custAtrbStringList: any = [];
    tableOn: boolean = true;
    buildingNameTooLong: any = [];

    @ViewChild(S25TableComponent) table: S25TableComponent;
    @Output() onChange = new EventEmitter();

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    async chooseProperty(sourceProp: string) {
        delete this.sourceProperty; // need to delete this here, otherwise table header won't change
        this.cd.detectChanges();

        let confirm = jSith.when(true);

        if (this.selectedItems?.length > 0) {
            confirm = AlertService.confirm(
                "Changing source property will discard your changes. Do you want to continue?",
            );
        }

        confirm.then(async (confirmed) => {
            if (!confirmed) {
                return;
            }

            if (this.selectedItems === "attributes" && !this.custAtrb) {
                return;
            }

            this.sourceProperty = sourceProp;

            if (this.sourceProperty !== "attributes") {
                this.tableOn = true;
                await this.createTable();
                if (this.table) {
                    this.table.dataSource.columns[0].header = this.getColumnHeader();
                    await this.table.refresh(true);
                }
                this.cd.detectChanges();
            } else {
                this.typeName = "attribute";
                this.tableOn = false;
                const getCAStringList = await CustomAttributeService.getCustomAttributes(4);
                this.custAtrbStringList = getCAStringList.custom_attributes.attribute;
                this.custAtrbStringList = this.custAtrbStringList
                    .filter((a: any) => {
                        return a.cust_atrb_type === "S";
                    })
                    .map((item: any) => {
                        return { itemId: item.cust_atrb_id, itemName: item.cust_atrb_name };
                    });
                this.cd.detectChanges();
            }
        });
    }

    chooseCustomAttribute(selected: DropDownItem) {
        this.tableOn = true;
        this.createTable();
        if (this.table) {
            this.table.dataSource.columns[0].header = this.getColumnHeader();
            this.table.refresh(true);
        }
        this.cd.detectChanges();
        //console.log("custAtrb", this.custAtrb);
    }

    async createTable() {
        this.tableData = this.getTableConfig();
    }
    private async getSourceItems(): Promise<any> {
        switch (this.sourceProperty) {
            case "partitions":
                this.typeName = "partition";
                return SearchCriteriaService.getLocationPartitions();
            case "categories":
                this.typeName = "category";
                return SearchCriteriaService.getSpaceCategories();
            case "attributes":
                this.typeName = "attribute";
                const getCAStringList = await CustomAttributeService.getCustomAttributes(4);
                this.custAtrbStringList = getCAStringList.custom_attributes.attribute;
                this.custAtrbStringList = this.custAtrbStringList
                    .filter((a: any) => {
                        return a.cust_atrb_type === "S";
                    })
                    .map((item: any) => {
                        return { itemId: item.cust_atrb_id, itemName: item.cust_atrb_name };
                    });

                if (!this.custAtrb) {
                    this.tableOn = false;
                    return;
                } else {
                    this.tableOn = true;
                    let getVals: any = await CustomAttributeService.getDistinctValues(4, Number(this.custAtrb.itemId));
                    getVals = getVals?.data || [];
                    let count = 1;
                    getVals = S25Util.array.forceArray(getVals).map((item: any) => {
                        return { itemId: count++, itemName: item.val, locIds: item.itemIds }; // do mapping  need itemId/UUID for table selected items
                    });
                    return getVals;
                }

            default:
                console.log("should not have called this");
                return Promise.resolve([]);
        }
    }
    private getColumnHeader() {
        let sourceName;
        switch (this.sourceProperty) {
            case "partitions":
                sourceName = "Partition Name";
                break;
            case "categories":
                sourceName = "Category Name";
                break;
            case "attributes":
                sourceName = "Attribute Value";
                break;
            default:
                sourceName = "Source Item Name";
                break;
        }
        return sourceName;
    }

    async ngOnInit() {
        // this.tableData = this.getTableConfig();
        this.isInit = true;
        this.cd.detectChanges();
    }

    getTableConfig(): Table.DataSource {
        return {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: [
                {
                    id: "itemName",
                    header: this.getColumnHeader(),
                },
                {
                    id: "buildingName",
                    header: "Building Name",
                    sortable: false,
                },
            ],
        };
    }

    @Bind
    async getRows(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        if (query.forceRefresh) {
            CacheRepository.invalidateByService("SearchCriteriaService", "getLocationPartitions");
            CacheRepository.invalidateByService("SearchCriteriaService", "getSpaceCategories");
        }

        let sourceItems = await this.getSourceItems();
        this.sourceItems = sourceItems;
        for (let p of sourceItems) {
            p.buildingName = p.itemName || p.val; //use for save mapping
        }
        return {
            rows: sourceItems.map(this.mapToRow) || [],
        };
    }

    mapToRow(item: any): Table.Row {
        return {
            id: item.itemId || item.itemIds,
            name: item.itemName || item.val,
            cells: {
                itemName: { text: item.itemName },
                buildingName: {
                    component: S25EditableTextComponent,
                    inputs: { val: item.buildingName },
                    outputs: {
                        valChange: (val: any, row: Table.Row) => {
                            item.buildingName = val;
                        },
                    },
                },
            },
        };
    }

    @Bind
    async refresh(val: boolean) {
        val && (await this.table.refresh(true));
    }

    @Bind
    async onSave() {
        let buildings = await BuildingService.getBuildings();
        this.buildings = S25Util.array.forceArray(buildings?.items); // get all buildings then compare name via new building name
        //filter selected item
        const selectedPartitions = this.sourceItems.filter((p: any) => {
            return this.selectedItems.find((s: any) => {
                return s === p.itemId;
            });
        });

        this.buildingNameTooLong = selectedPartitions.filter(function (item: any) {
            return item.buildingName.length > 80;
        });

        if (this.buildingNameTooLong.length > 0) {
            alert("Building name can't be more than 80 characters.");
            return;
        }

        let finalSelectedPartitions: any = [];

        jSith.forEach(selectedPartitions, (s: any, item: any) => {
            let find: any = this.buildings.find((b: any) => {
                return item.buildingName.toString().trim() === b.buildingName.toString().trim();
            });
            !find ? finalSelectedPartitions.push(item) : this.matchFound.push(item);
        });

        if (finalSelectedPartitions.length > 0) {
            let payload = [];
            for (let p of finalSelectedPartitions) {
                payload.push({ buildingName: p.buildingName.toString() });
            }
            // console.log({finalSelectedPartitions: finalSelectedPartitions});
            await BuildingService.postBuilding(payload, true).then((resp: any) => {
                if (resp?.content?.data?.items) {
                    let respItem = resp.content.data.items;
                    let typeName = this.typeName;
                    if (this.assignLocations) {
                        if (this.sourceProperty === "attributes") {
                            jSith.forEach(respItem, function (i, p) {
                                const payloadLoc = {
                                    idList: S25Util.array.forceArray(finalSelectedPartitions[i].locIds),
                                    buildingId: p.id,
                                };
                                return SpaceService.microUpdate(payloadLoc).then((resp: any) => {
                                    //this.msg="Success!";
                                });
                            });
                        } else {
                            jSith.forEach(respItem, function (i, p) {
                                return SpaceService.getSpacesByTypeId(
                                    [finalSelectedPartitions[i].itemId],
                                    typeName,
                                ).then((data: any) => {
                                    data = S25Util.array.forceArray(data);
                                    let partitionSpaces: any = [];
                                    partitionSpaces = data.map((item: any) => {
                                        return { itemId: item.space_id };
                                    });
                                    const payloadLoc = { idList: S25Util.toItemIds(partitionSpaces), buildingId: p.id };
                                    return SpaceService.microUpdate(payloadLoc).then((resp: any) => {
                                        //this.msg="Success!";
                                    });
                                });
                            });
                        } //end att ELSE
                    }
                }
                this.msg = "Success!";
            });
        } //end if
        await S25Util.delay(60);
        this.selectedItems = [];
        await this.table.refresh(true);
        this.cd.detectChanges();
    }

    selectedChange(selectItem: any) {
        this.selectedItems = Array.from(selectItem);
        this.cd.detectChanges();
    }

    toggleChanged() {
        this.cd.detectChanges();
    }

    error(error: any) {
        S25Util.showError(error);
    }
}
